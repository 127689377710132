.root {
    width: 100%;
    background-color: var(--whitebase);
}

.wrapper {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    min-height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    flex-wrap: wrap;
    padding-top: 10px;
}

.h1 {
    margin: 0;
    padding: 0;
    display: flex;
}

.logo {
    max-width: 600px;
    height: auto;
}

.logoText {
    width: 62px;
    height: 33px;
    font-family: "Roboto", sans-serif;
    font-size: 10px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.28px;
    text-align: justify;
    color: #ffffff;
    margin-left: 9px;
}

.middleText {
    font-family: "Roboto", sans-serif;
    font-size: 10px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    font-weight: bold;
    letter-spacing: 0.28px;
    text-align: justify;
    color: #ffffff;
    margin-left: 9px;
    text-transform: uppercase;
    order: 1;
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;
}

.link {
    font-family: inherit;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: var(--primary-450);
    text-decoration: none;
    margin-bottom: 9px;
    text-transform: uppercase;
}

.link:focus {
    text-decoration: underline;
}

.rightWrapper {
    display: flex;
    align-items: center;
}

.signatureIcon {
    margin-left: auto;
    width: 20px;
    height: 19px;
}

.text {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black-700);
    margin-left: 4px;
}

/* --tablet-widths */
@media (min-width: 660px) {
    .wrapper {
        padding-top: 0;
    }

    .middleText {
        order: 0;
        width: auto;
    }
}

/* --desktop-widths */
@media (min-width: 1100px) {
    .wrapper {
        height: 116px;
    }

    .logo {
        max-width: 600px;
        height: auto;
    }

    .logoText {
        width: 121px;
        height: 69px;
        font-size: 18px;
        margin-left: 19px;
    }

    .middleText {
        font-size: 18px;
    }

    .link {
        font-size: 18px;
    }

    .signatureIcon {
        width: 41px;
        height: 36px;
    }

    .text {
        font-size: 20px;
        font-weight: bold;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
    .logo {
        max-width: 100%;
        height: auto;
    }
}
