.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    max-width: 395px;
}

.h2 {
    margin: 22px 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.67;
    letter-spacing: normal;
    color: var(--black-700);
}

.text {
    margin: 0 10px;
    max-width: 466px;
    display: inline-block;
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.34;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-700);
    margin-bottom: 10px;
}

.textForm {
    padding: 0;
    margin: 0;
}

.button {
    margin-top: 20px;
}

.buttonDsc {
    margin-top: 10px;
    background-color: #f6685e !important;
}

.h3 {
    background-color: var(--tertiary-500);
    color: var(--whitebase);
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    display: block;
    margin: 0;
    padding: 0;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-bottom: 14px;
}

.buttonBlue {
    background-color: var(--chrysm-light-blue) !important;
    height: 40px !important;
    margin-bottom: 6px;
    color: black !important;
}

.loginArea {
    background-color: var(--whitebase);
    margin-top: 14px;
    padding-bottom: 26px;
    width: 100%;
}

.inner {
    width: 100%;
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.inputUser {
    margin-top: 21px;
}

.inputPass {
    margin-top: 14px;
}

.checkBoxWrapper {
    margin-top: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* .checkBoxWrapper span {
    font-family: inherit;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: inherit;
} */

.loginButton {
    margin-top: 15px;
    max-width: 122px;
    margin-bottom: 14px;
}

.link {
    font-family: inherit;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: var(--warning-300);
    text-decoration: none;
    margin-bottom: 9px;
}

.link:focus {
    text-decoration: underline;
}

.article {
    width: 340px;
    min-height: 170px;
    border-radius: 4px;
    border: solid 2px var(--black-700);
    position: relative;
    margin-top: 26px;
    margin-left: 4px;
}

.articleTitleWrapper {
    width: 238px;
    height: 25px;
    padding-bottom: 2px;
    border-radius: 4px;
    background-color: var(--warning-300);
    border-left: solid 3px var(--tertiary-050);
    border-right: solid 3px var(--tertiary-050);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -13px;
    left: calc(50% - 119px);
}

.articleTitle {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;

    text-align: center;

    color: var(--whitebase);
}

.articleList {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 18px;
}

.articleItem {
    padding-left: 28px;
    padding-right: 5px;
    position: relative;
    margin-bottom: 11px;
}
.articleItem:last-child {
    margin-bottom: 0;
}

.articleIcon {
    position: absolute;
    left: 9px;
    top: 6px;
    width: 13px;
    height: 13px;
    fill: var(--success-400-base);
}

.articleIconS {
    position: absolute;
    left: 11px;
    top: 3px;
    color: var(--warning-300);
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.articleText {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: 0.1px;
    color: var(--black-700);
    padding: 0;
    margin: 0;
}

.articleText b {
    font-weight: bold;
}

.fullwidth {
    width: 100%;
    text-align: center;
}

.checkBoxWrapper div div {
    display: flex;
    place-content: center;
}

/* --tablet-widths */
@media (min-width: 660px) {
    .article {
        width: 396px;
    }
}

/* --desktop-widths */
@media (min-width: 1024px) {
    .root {
        margin-bottom: 65px;
    }

    .wrapper {
        width: 859px;
    }

    .h2 {
        margin-top: 38px;
        padding-right: 36px;
    }
    .text {
        margin-top: 8px;
    }

    .button {
        margin-top: 20px;
    }
    .loginArea {
        margin-top: 11px;
    }
    .h3 {
        margin-bottom: 6px;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
    .root {
        max-width: auto;
    }
}
